import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card mb-5 mb-xl-10"
}
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title m-0" }
const _hoisted_4 = { class: "card-body p-9 pb-12" }
const _hoisted_5 = {
  key: 0,
  class: "text-primary fw-bolder"
}
const _hoisted_6 = { class: "d-flex gap-4" }
const _hoisted_7 = { class: "svg-icon svg-icon-3 m-0" }
const _hoisted_8 = { class: "d-flex flex-wrap gap-4" }
const _hoisted_9 = { class: "card mb-5 mb-xl-10" }
const _hoisted_10 = { class: "card-header" }
const _hoisted_11 = { class: "card-title m-0" }
const _hoisted_12 = { class: "card-toolbar flex-row-fluid justify-content-end gap-5" }
const _hoisted_13 = { class: "card-body p-9 pb-12" }
const _hoisted_14 = {
  key: 0,
  class: "text-primary fw-bolder"
}
const _hoisted_15 = { class: "d-flex gap-4" }
const _hoisted_16 = { class: "svg-icon svg-icon-3 m-0" }
const _hoisted_17 = { class: "d-flex flex-wrap gap-4" }
const _hoisted_18 = { class: "card mb-5 mb-xl-10" }
const _hoisted_19 = { class: "card-header" }
const _hoisted_20 = { class: "card-title m-0" }
const _hoisted_21 = { class: "card-toolbar flex-row-fluid justify-content-end gap-5" }
const _hoisted_22 = { class: "card-body p-9 pb-12" }
const _hoisted_23 = {
  key: 0,
  class: "text-primary fw-bolder"
}
const _hoisted_24 = { class: "d-flex gap-4" }
const _hoisted_25 = ["onClick"]
const _hoisted_26 = { class: "svg-icon svg-icon-3 m-0" }
const _hoisted_27 = ["onClick"]
const _hoisted_28 = { class: "svg-icon svg-icon-3 m-0" }
const _hoisted_29 = { class: "d-flex flex-wrap gap-4" }
const _hoisted_30 = ["onClick"]
const _hoisted_31 = { class: "d-flex justify-content-end" }
const _hoisted_32 = {
  key: 1,
  class: "card mb-5 mb-xl-10"
}
const _hoisted_33 = { class: "card-header" }
const _hoisted_34 = { class: "card-title m-0" }
const _hoisted_35 = { class: "card-toolbar flex-row-fluid justify-content-end gap-5" }
const _hoisted_36 = { class: "card-body p-9 pb-12" }
const _hoisted_37 = {
  key: 0,
  class: "text-primary fw-bolder"
}
const _hoisted_38 = { class: "d-flex gap-4" }
const _hoisted_39 = ["onClick"]
const _hoisted_40 = { class: "svg-icon svg-icon-3 m-0" }
const _hoisted_41 = ["onClick"]
const _hoisted_42 = { class: "svg-icon svg-icon-3 m-0" }
const _hoisted_43 = { class: "d-flex flex-wrap gap-4" }
const _hoisted_44 = ["onClick"]
const _hoisted_45 = { class: "d-flex justify-content-end" }
const _hoisted_46 = { class: "card mb-5 mb-xl-10" }
const _hoisted_47 = { class: "card-header" }
const _hoisted_48 = { class: "card-title m-0" }
const _hoisted_49 = { class: "card-toolbar flex-row-fluid justify-content-end gap-5" }
const _hoisted_50 = { class: "card-body p-9 pb-12" }
const _hoisted_51 = {
  key: 0,
  class: "text-primary fw-bolder"
}
const _hoisted_52 = { class: "d-flex gap-4" }
const _hoisted_53 = ["onClick"]
const _hoisted_54 = { class: "svg-icon svg-icon-3 m-0" }
const _hoisted_55 = ["onClick"]
const _hoisted_56 = { class: "svg-icon svg-icon-3 m-0" }
const _hoisted_57 = { class: "d-flex flex-wrap gap-4" }
const _hoisted_58 = ["onClick"]
const _hoisted_59 = { class: "d-flex justify-content-end" }
const _hoisted_60 = {
  key: 0,
  class: "text-primary fw-bolder"
}
const _hoisted_61 = { class: "d-flex gap-4" }
const _hoisted_62 = ["onClick"]
const _hoisted_63 = { class: "svg-icon svg-icon-3 m-0" }
const _hoisted_64 = ["onClick"]
const _hoisted_65 = { class: "svg-icon svg-icon-3 m-0" }
const _hoisted_66 = { class: "d-flex flex-wrap gap-4" }
const _hoisted_67 = ["onClick"]
const _hoisted_68 = { class: "d-flex justify-content-end" }
const _hoisted_69 = { class: "card mb-5 mb-xl-10" }
const _hoisted_70 = { class: "card-header" }
const _hoisted_71 = { class: "card-title m-0" }
const _hoisted_72 = { class: "card-toolbar flex-row-fluid justify-content-end gap-5" }
const _hoisted_73 = { class: "card-body p-9 pb-12" }
const _hoisted_74 = {
  key: 0,
  class: "text-primary fw-bolder"
}
const _hoisted_75 = { class: "d-flex gap-4" }
const _hoisted_76 = ["onClick"]
const _hoisted_77 = { class: "svg-icon svg-icon-3 m-0" }
const _hoisted_78 = ["onClick"]
const _hoisted_79 = { class: "svg-icon svg-icon-3 m-0" }
const _hoisted_80 = { class: "d-flex flex-wrap gap-4" }
const _hoisted_81 = ["onClick"]
const _hoisted_82 = { class: "d-flex justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_ContactCard = _resolveComponent("ContactCard")!
  const _component_EmptyData = _resolveComponent("EmptyData")!
  const _component_RegistrationCard = _resolveComponent("RegistrationCard")!
  const _component_WarehouseCard = _resolveComponent("WarehouseCard")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_InventoryCard = _resolveComponent("InventoryCard")!
  const _component_BillingCard = _resolveComponent("BillingCard")!
  const _component_FinancialCard = _resolveComponent("FinancialCard")!
  const _component_TaxCard = _resolveComponent("TaxCard")!
  const _component_ContactEditModal = _resolveComponent("ContactEditModal")!
  const _component_RegistrationEditModal = _resolveComponent("RegistrationEditModal")!
  const _component_BillingEditModal = _resolveComponent("BillingEditModal")!
  const _component_WarehouseEditModal = _resolveComponent("WarehouseEditModal")!
  const _component_InventoryEditModal = _resolveComponent("InventoryEditModal")!
  const _component_FinancialEditModal = _resolveComponent("FinancialEditModal")!
  const _component_TaxEditModal = _resolveComponent("TaxEditModal")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.baseData.show_contact_identification)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h3", null, _toDisplayString(_ctx.$t("kyc.information.contactInformation.title")), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            (!_ctx.isNullData(_ctx.contactView))
              ? (_openBlock(), _createBlock(_component_ContactCard, {
                  key: 0,
                  type: _ctx.getKycCommonTypeParams.entityType,
                  data: _ctx.contactView
                }, {
                  "after-title": _withCtx(() => [
                    (_ctx.contactView.identification.kyc_level)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t("kyc.level.title")) + " " + _toDisplayString(_ctx.contactView.identification.kyc_level || "--"), 1))
                      : _createCommentVNode("", true)
                  ]),
                  toolbar: _withCtx(() => [
                    _createElementVNode("div", _hoisted_6, [
                      (_ctx.buttonShowControl(_ctx.contactView).edit)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            type: "button",
                            class: "btn btn-light btn-icon btn-sm",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editContact(_ctx.contactView.id)))
                          }, [
                            _createElementVNode("span", _hoisted_7, [
                              _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/art/art005.svg" })
                            ])
                          ]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  footer: _withCtx(() => [
                    _createElementVNode("div", _hoisted_8, [
                      (_ctx.buttonShowControl(_ctx.contactView).submit)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            type: "button",
                            class: "btn btn-primary",
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (
                  _ctx.onSubmitForApprovalClick(
                    _ctx.CardComponentsType.Contact,
                    _ctx.contactView.id
                  )
                ))
                          }, _toDisplayString(_ctx.$t("kyc.actions.submitForApproval")), 1))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 1
                }, 8, ["type", "data"]))
              : _createCommentVNode("", true),
            (_ctx.isNullData(_ctx.contactView))
              ? (_openBlock(), _createBlock(_component_EmptyData, { key: 1 }))
              : _createCommentVNode("", true)
          ])
        ])), [
          [_directive_loading, _ctx.loading || _ctx.cardComponentsLoading[_ctx.CardComponentsType.Contact]]
        ])
      : _createCommentVNode("", true),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t("kyc.information.registrationInformation.title")), 1)
        ]),
        _createElementVNode("div", _hoisted_12, [
          (_ctx.isNullData(_ctx.registrationView))
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                type: "button",
                class: "btn btn-light-primary",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.addRegistration && _ctx.addRegistration(...args)))
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("common.add")), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        (!_ctx.isNullData(_ctx.registrationView))
          ? (_openBlock(), _createBlock(_component_RegistrationCard, {
              key: 0,
              type: _ctx.getKycCommonTypeParams.entityType,
              data: _ctx.registrationView
            }, {
              "after-title": _withCtx(() => [
                (_ctx.registrationView.identification.kyc_level)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.$t("kyc.level.title")) + " " + _toDisplayString(_ctx.registrationView.identification.kyc_level || "--"), 1))
                  : _createCommentVNode("", true)
              ]),
              toolbar: _withCtx(() => [
                _createElementVNode("div", _hoisted_15, [
                  (_ctx.buttonShowControl(_ctx.registrationView).edit)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        type: "button",
                        class: "btn btn-light btn-icon btn-sm",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.editRegistration(_ctx.registrationView.id)))
                      }, [
                        _createElementVNode("span", _hoisted_16, [
                          _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/art/art005.svg" })
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              footer: _withCtx(() => [
                _createElementVNode("div", _hoisted_17, [
                  (_ctx.buttonShowControl(_ctx.registrationView).submit)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        type: "button",
                        class: "btn btn-primary",
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (
                  _ctx.onSubmitForApprovalClick(
                    _ctx.CardComponentsType.Registration,
                    _ctx.registrationView.id
                  )
                ))
                      }, _toDisplayString(_ctx.$t("kyc.actions.submitForApproval")), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }, 8, ["type", "data"]))
          : _createCommentVNode("", true),
        (_ctx.isNullData(_ctx.registrationView))
          ? (_openBlock(), _createBlock(_component_EmptyData, { key: 1 }))
          : _createCommentVNode("", true)
      ])
    ])), [
      [_directive_loading, 
        _ctx.loading || _ctx.cardComponentsLoading[_ctx.CardComponentsType.Registration]
      ]
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_18, [
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("div", _hoisted_20, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t("kyc.information.warehouseInformation.title")), 1)
        ]),
        _createElementVNode("div", _hoisted_21, [
          (_ctx.buttonShowControl(null, _ctx.warehousePagination.total).add)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                type: "button",
                class: "btn btn-light-primary",
                onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.addWarehouse && _ctx.addWarehouse(...args)))
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("common.add")), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_22, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.warehouseList, (item, index) => {
          return (_openBlock(), _createBlock(_component_WarehouseCard, {
            key: index,
            type: _ctx.getKycCommonTypeParams.entityType,
            data: item,
            index: 
            index +
            (_ctx.warehousePagination.page - 1) * _ctx.warehousePagination.rowsPerPage
          
          }, {
            "after-title": _withCtx(() => [
              (item.identification.kyc_level)
                ? (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(_ctx.$t("kyc.level.title")) + " " + _toDisplayString(item.identification.kyc_level || "--"), 1))
                : _createCommentVNode("", true)
            ]),
            toolbar: _withCtx(() => [
              _createElementVNode("div", _hoisted_24, [
                (_ctx.buttonShowControl(item).edit)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      type: "button",
                      class: "btn btn-light btn-icon btn-sm",
                      onClick: ($event: any) => (_ctx.editWarehouse(item.id))
                    }, [
                      _createElementVNode("span", _hoisted_26, [
                        _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/art/art005.svg" })
                      ])
                    ], 8, _hoisted_25))
                  : _createCommentVNode("", true),
                (_ctx.buttonShowControl(item).delete)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      type: "button",
                      class: "btn btn-light-danger btn-icon btn-sm",
                      onClick: ($event: any) => (
                  _ctx.onDeleteButtonClick(_ctx.CardComponentsType.Warehouse, item.id)
                )
                    }, [
                      _createElementVNode("span", _hoisted_28, [
                        _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen027.svg" })
                      ])
                    ], 8, _hoisted_27))
                  : _createCommentVNode("", true)
              ])
            ]),
            footer: _withCtx(() => [
              _createElementVNode("div", _hoisted_29, [
                (_ctx.buttonShowControl(item).submit)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      type: "button",
                      class: "btn btn-primary",
                      onClick: ($event: any) => (
                  _ctx.onSubmitForApprovalClick(
                    _ctx.CardComponentsType.Warehouse,
                    item.id
                  )
                )
                    }, _toDisplayString(_ctx.$t("kyc.actions.submitForApproval")), 9, _hoisted_30))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 2
          }, 1032, ["type", "data", "index"]))
        }), 128)),
        _createElementVNode("div", _hoisted_31, [
          _createVNode(_component_el_pagination, {
            "current-page": _ctx.warehousePagination.page,
            "onUpdate:current-page": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.warehousePagination.page) = $event)),
            onCurrentChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.updateCardInfo(_ctx.CardComponentsType.Warehouse))),
            "page-size": _ctx.warehousePagination.rowsPerPage,
            "pager-count": 5,
            layout: "prev, pager, next",
            total: _ctx.warehousePagination.total,
            "hide-on-single-page": true,
            background: ""
          }, null, 8, ["current-page", "page-size", "total"])
        ]),
        (_ctx.warehouseList.length === 0)
          ? (_openBlock(), _createBlock(_component_EmptyData, { key: 0 }))
          : _createCommentVNode("", true)
      ])
    ])), [
      [_directive_loading, _ctx.loading || _ctx.cardComponentsLoading[_ctx.CardComponentsType.Warehouse]]
    ]),
    (_ctx.getKycCommonTypeParams.entityType === _ctx.KycType.Natural)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_32, [
          _createElementVNode("div", _hoisted_33, [
            _createElementVNode("div", _hoisted_34, [
              _createElementVNode("h3", null, _toDisplayString(_ctx.$t("kyc.information.inventoryInformation.title")), 1)
            ]),
            _createElementVNode("div", _hoisted_35, [
              (_ctx.buttonShowControl(null, _ctx.inventoryPagination.total).add)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "button",
                    class: "btn btn-light-primary",
                    onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.addInventory && _ctx.addInventory(...args)))
                  }, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("common.add")), 1)
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_36, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.inventoryList, (item, index) => {
              return (_openBlock(), _createBlock(_component_InventoryCard, {
                key: index,
                type: _ctx.getKycCommonTypeParams.entityType,
                data: item,
                index: 
            index +
            (_ctx.inventoryPagination.page - 1) * _ctx.inventoryPagination.rowsPerPage
          
              }, {
                "after-title": _withCtx(() => [
                  (item.identification.kyc_level)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_37, _toDisplayString(_ctx.$t("kyc.level.title")) + " " + _toDisplayString(item.identification.kyc_level || "--"), 1))
                    : _createCommentVNode("", true)
                ]),
                toolbar: _withCtx(() => [
                  _createElementVNode("div", _hoisted_38, [
                    (_ctx.buttonShowControl(item).edit)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          type: "button",
                          class: "btn btn-light btn-icon btn-sm",
                          onClick: ($event: any) => (_ctx.editInventory(item.id))
                        }, [
                          _createElementVNode("span", _hoisted_40, [
                            _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/art/art005.svg" })
                          ])
                        ], 8, _hoisted_39))
                      : _createCommentVNode("", true),
                    (_ctx.buttonShowControl(item).delete)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 1,
                          type: "button",
                          class: "btn btn-light-danger btn-icon btn-sm",
                          onClick: ($event: any) => (
                  _ctx.onDeleteButtonClick(_ctx.CardComponentsType.Inventory, item.id)
                )
                        }, [
                          _createElementVNode("span", _hoisted_42, [
                            _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen027.svg" })
                          ])
                        ], 8, _hoisted_41))
                      : _createCommentVNode("", true)
                  ])
                ]),
                footer: _withCtx(() => [
                  _createElementVNode("div", _hoisted_43, [
                    (_ctx.buttonShowControl(item).submit)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          type: "button",
                          class: "btn btn-primary",
                          onClick: ($event: any) => (
                  _ctx.onSubmitForApprovalClick(
                    _ctx.CardComponentsType.Inventory,
                    item.id
                  )
                )
                        }, _toDisplayString(_ctx.$t("kyc.actions.submitForApproval")), 9, _hoisted_44))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 2
              }, 1032, ["type", "data", "index"]))
            }), 128)),
            _createElementVNode("div", _hoisted_45, [
              _createVNode(_component_el_pagination, {
                "current-page": _ctx.inventoryPagination.page,
                "onUpdate:current-page": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.inventoryPagination.page) = $event)),
                onCurrentChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.updateCardInfo(_ctx.CardComponentsType.Inventory))),
                "page-size": _ctx.inventoryPagination.rowsPerPage,
                "pager-count": 5,
                layout: "prev, pager, next",
                total: _ctx.inventoryPagination.total,
                "hide-on-single-page": true,
                background: ""
              }, null, 8, ["current-page", "page-size", "total"])
            ]),
            (_ctx.inventoryList.length === 0)
              ? (_openBlock(), _createBlock(_component_EmptyData, { key: 0 }))
              : _createCommentVNode("", true)
          ])
        ])), [
          [_directive_loading, _ctx.loading || _ctx.cardComponentsLoading[_ctx.CardComponentsType.Inventory]]
        ])
      : _createCommentVNode("", true),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_46, [
      _createElementVNode("div", _hoisted_47, [
        _createElementVNode("div", _hoisted_48, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t("kyc.information.financialInformation.title")), 1)
        ]),
        _createElementVNode("div", _hoisted_49, [
          (_ctx.buttonShowControl(null, _ctx.billingPagination.total).add)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                type: "button",
                class: "btn btn-light",
                onClick: _cache[11] || (_cache[11] = 
//@ts-ignore
(...args) => (_ctx.addBilling && _ctx.addBilling(...args)))
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("kyc.actions.addBillingAddress")), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.buttonShowControl(null, _ctx.financialPagination.total).add)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                type: "button",
                class: "btn btn-light-primary",
                onClick: _cache[12] || (_cache[12] = 
//@ts-ignore
(...args) => (_ctx.addFinancial && _ctx.addFinancial(...args)))
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("kyc.actions.addFinancialAccount")), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_50, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.billingList, (item, index) => {
          return (_openBlock(), _createBlock(_component_BillingCard, {
            key: index,
            type: _ctx.getKycCommonTypeParams.entityType,
            data: item,
            index: 
            index + (_ctx.billingPagination.page - 1) * _ctx.billingPagination.rowsPerPage
          
          }, {
            "after-title": _withCtx(() => [
              (item.identification.kyc_level)
                ? (_openBlock(), _createElementBlock("span", _hoisted_51, _toDisplayString(_ctx.$t("kyc.level.title")) + " " + _toDisplayString(item.identification.kyc_level || "--"), 1))
                : _createCommentVNode("", true)
            ]),
            toolbar: _withCtx(() => [
              _createElementVNode("div", _hoisted_52, [
                (_ctx.buttonShowControl(item).edit)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      type: "button",
                      class: "btn btn-light btn-icon btn-sm",
                      onClick: ($event: any) => (_ctx.editBilling(item.id))
                    }, [
                      _createElementVNode("span", _hoisted_54, [
                        _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/art/art005.svg" })
                      ])
                    ], 8, _hoisted_53))
                  : _createCommentVNode("", true),
                (_ctx.buttonShowControl(item).delete)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      type: "button",
                      class: "btn btn-light-danger btn-icon btn-sm",
                      onClick: ($event: any) => (
                  _ctx.onDeleteButtonClick(_ctx.CardComponentsType.Billing, item.id)
                )
                    }, [
                      _createElementVNode("span", _hoisted_56, [
                        _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen027.svg" })
                      ])
                    ], 8, _hoisted_55))
                  : _createCommentVNode("", true)
              ])
            ]),
            footer: _withCtx(() => [
              _createElementVNode("div", _hoisted_57, [
                (_ctx.buttonShowControl(item).submit)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      type: "button",
                      class: "btn btn-primary",
                      onClick: ($event: any) => (
                  _ctx.onSubmitForApprovalClick(_ctx.CardComponentsType.Billing, item.id)
                )
                    }, _toDisplayString(_ctx.$t("kyc.actions.submitForApproval")), 9, _hoisted_58))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 2
          }, 1032, ["type", "data", "index"]))
        }), 128)),
        _createElementVNode("div", _hoisted_59, [
          _createVNode(_component_el_pagination, {
            "current-page": _ctx.billingPagination.page,
            "onUpdate:current-page": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.billingPagination.page) = $event)),
            onCurrentChange: _cache[14] || (_cache[14] = ($event: any) => (_ctx.updateCardInfo(_ctx.CardComponentsType.Billing))),
            "page-size": _ctx.billingPagination.rowsPerPage,
            "pager-count": 5,
            layout: "prev, pager, next",
            total: _ctx.billingPagination.total,
            "hide-on-single-page": true,
            background: ""
          }, null, 8, ["current-page", "page-size", "total"])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.financialList, (item, index) => {
          return (_openBlock(), _createBlock(_component_FinancialCard, {
            key: index,
            type: _ctx.getKycCommonTypeParams.entityType,
            data: item,
            index: 
            index +
            (_ctx.financialPagination.page - 1) * _ctx.financialPagination.rowsPerPage
          
          }, {
            "after-title": _withCtx(() => [
              (item.identification.kyc_level)
                ? (_openBlock(), _createElementBlock("span", _hoisted_60, _toDisplayString(_ctx.$t("kyc.level.title")) + " " + _toDisplayString(item.identification.kyc_level || "--"), 1))
                : _createCommentVNode("", true)
            ]),
            toolbar: _withCtx(() => [
              _createElementVNode("div", _hoisted_61, [
                (_ctx.buttonShowControl(item).edit)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      type: "button",
                      class: "btn btn-light btn-icon btn-sm",
                      onClick: ($event: any) => (_ctx.editFinancial(item.id))
                    }, [
                      _createElementVNode("span", _hoisted_63, [
                        _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/art/art005.svg" })
                      ])
                    ], 8, _hoisted_62))
                  : _createCommentVNode("", true),
                (_ctx.buttonShowControl(item).delete)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      type: "button",
                      class: "btn btn-light-danger btn-icon btn-sm",
                      onClick: ($event: any) => (
                  _ctx.onDeleteButtonClick(_ctx.CardComponentsType.Financial, item.id)
                )
                    }, [
                      _createElementVNode("span", _hoisted_65, [
                        _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen027.svg" })
                      ])
                    ], 8, _hoisted_64))
                  : _createCommentVNode("", true)
              ])
            ]),
            footer: _withCtx(() => [
              _createElementVNode("div", _hoisted_66, [
                (_ctx.buttonShowControl(item).submit)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      type: "button",
                      class: "btn btn-primary",
                      onClick: ($event: any) => (
                  _ctx.onSubmitForApprovalClick(
                    _ctx.CardComponentsType.Financial,
                    item.id
                  )
                )
                    }, _toDisplayString(_ctx.$t("kyc.actions.submitForApproval")), 9, _hoisted_67))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 2
          }, 1032, ["type", "data", "index"]))
        }), 128)),
        _createElementVNode("div", _hoisted_68, [
          _createVNode(_component_el_pagination, {
            "current-page": _ctx.financialPagination.page,
            "onUpdate:current-page": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.financialPagination.page) = $event)),
            onCurrentChange: _cache[16] || (_cache[16] = ($event: any) => (_ctx.updateCardInfo(_ctx.CardComponentsType.Financial))),
            "page-size": _ctx.financialPagination.rowsPerPage,
            "pager-count": 5,
            layout: "prev, pager, next",
            total: _ctx.financialPagination.total,
            "hide-on-single-page": true,
            background: ""
          }, null, 8, ["current-page", "page-size", "total"])
        ]),
        (_ctx.billingList.length === 0 && _ctx.financialList.length === 0)
          ? (_openBlock(), _createBlock(_component_EmptyData, { key: 0 }))
          : _createCommentVNode("", true)
      ])
    ])), [
      [_directive_loading, 
        _ctx.loading ||
        _ctx.cardComponentsLoading[_ctx.CardComponentsType.Billing] ||
        _ctx.cardComponentsLoading[_ctx.CardComponentsType.Financial]
      ]
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_69, [
      _createElementVNode("div", _hoisted_70, [
        _createElementVNode("div", _hoisted_71, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t("kyc.information.taxInformation.title")), 1)
        ]),
        _createElementVNode("div", _hoisted_72, [
          (_ctx.buttonShowControl(null, _ctx.taxPagination.total).add)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                type: "button",
                class: "btn btn-light-primary",
                onClick: _cache[17] || (_cache[17] = 
//@ts-ignore
(...args) => (_ctx.addTax && _ctx.addTax(...args)))
              }, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t("common.add")), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_73, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.taxList, (item, index) => {
          return (_openBlock(), _createBlock(_component_TaxCard, {
            key: index,
            type: _ctx.getKycCommonTypeParams.entityType,
            data: item,
            index: index + (_ctx.taxPagination.page - 1) * _ctx.taxPagination.rowsPerPage
          }, {
            "after-title": _withCtx(() => [
              (item.identification.kyc_level)
                ? (_openBlock(), _createElementBlock("span", _hoisted_74, _toDisplayString(_ctx.$t("kyc.level.title")) + " " + _toDisplayString(item.identification.kyc_level || "--"), 1))
                : _createCommentVNode("", true)
            ]),
            toolbar: _withCtx(() => [
              _createElementVNode("div", _hoisted_75, [
                (_ctx.buttonShowControl(item).edit)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      type: "button",
                      class: "btn btn-light btn-icon btn-sm",
                      onClick: ($event: any) => (_ctx.editTax(item.id))
                    }, [
                      _createElementVNode("span", _hoisted_77, [
                        _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/art/art005.svg" })
                      ])
                    ], 8, _hoisted_76))
                  : _createCommentVNode("", true),
                (_ctx.buttonShowControl(item).delete)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      type: "button",
                      class: "btn btn-light-danger btn-icon btn-sm",
                      onClick: ($event: any) => (_ctx.onDeleteButtonClick(_ctx.CardComponentsType.Tax, item.id))
                    }, [
                      _createElementVNode("span", _hoisted_79, [
                        _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen027.svg" })
                      ])
                    ], 8, _hoisted_78))
                  : _createCommentVNode("", true)
              ])
            ]),
            footer: _withCtx(() => [
              _createElementVNode("div", _hoisted_80, [
                (_ctx.buttonShowControl(item).submit)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      type: "button",
                      class: "btn btn-primary",
                      onClick: ($event: any) => (
                  _ctx.onSubmitForApprovalClick(_ctx.CardComponentsType.Tax, item.id)
                )
                    }, _toDisplayString(_ctx.$t("kyc.actions.submitForApproval")), 9, _hoisted_81))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 2
          }, 1032, ["type", "data", "index"]))
        }), 128)),
        _createElementVNode("div", _hoisted_82, [
          _createVNode(_component_el_pagination, {
            "current-page": _ctx.taxPagination.page,
            "onUpdate:current-page": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.taxPagination.page) = $event)),
            onCurrentChange: _cache[19] || (_cache[19] = ($event: any) => (_ctx.updateCardInfo(_ctx.CardComponentsType.Tax))),
            "page-size": _ctx.taxPagination.rowsPerPage,
            "pager-count": 5,
            layout: "prev, pager, next",
            total: _ctx.taxPagination.total,
            "hide-on-single-page": true,
            background: ""
          }, null, 8, ["current-page", "page-size", "total"])
        ]),
        (_ctx.taxList.length === 0)
          ? (_openBlock(), _createBlock(_component_EmptyData, { key: 0 }))
          : _createCommentVNode("", true)
      ])
    ])), [
      [_directive_loading, _ctx.loading || _ctx.cardComponentsLoading[_ctx.CardComponentsType.Tax]]
    ]),
    _createVNode(_component_ContactEditModal, {
      ref: "contactEditModal",
      type: _ctx.getKycCommonTypeParams.entityType,
      id: Number(_ctx.contactEditId),
      "update-section": "identification",
      onUpdateInfo: _cache[20] || (_cache[20] = ($event: any) => (_ctx.updateCardInfo(_ctx.CardComponentsType.Contact)))
    }, null, 8, ["type", "id"]),
    _createVNode(_component_RegistrationEditModal, {
      ref: "registrationEditModal",
      type: _ctx.getKycCommonTypeParams.entityType,
      id: Number(_ctx.registrationEditId),
      "update-section": "identification",
      onUpdateInfo: _cache[21] || (_cache[21] = ($event: any) => (_ctx.updateCardInfo(_ctx.CardComponentsType.Registration)))
    }, null, 8, ["type", "id"]),
    _createVNode(_component_BillingEditModal, {
      ref: "billingEditModal",
      type: _ctx.getKycCommonTypeParams.entityType,
      id: Number(_ctx.billingEditId),
      onUpdateInfo: _cache[22] || (_cache[22] = ($event: any) => (_ctx.updateCardInfo(_ctx.CardComponentsType.Billing)))
    }, null, 8, ["type", "id"]),
    _createVNode(_component_WarehouseEditModal, {
      ref: "warehouseEditModal",
      type: _ctx.getKycCommonTypeParams.entityType,
      id: Number(_ctx.warehouseEditId),
      "update-section": "identification",
      onUpdateInfo: _cache[23] || (_cache[23] = ($event: any) => (_ctx.updateCardInfo(_ctx.CardComponentsType.Warehouse)))
    }, null, 8, ["type", "id"]),
    _createVNode(_component_InventoryEditModal, {
      ref: "inventoryEditModal",
      type: _ctx.getKycCommonTypeParams.entityType,
      id: Number(_ctx.inventoryEditId),
      "update-section": "identification",
      onUpdateInfo: _cache[24] || (_cache[24] = ($event: any) => (_ctx.updateCardInfo(_ctx.CardComponentsType.Inventory)))
    }, null, 8, ["type", "id"]),
    _createVNode(_component_FinancialEditModal, {
      ref: "financialEditModal",
      type: _ctx.getKycCommonTypeParams.entityType,
      id: Number(_ctx.financialEditId),
      "update-section": "identification",
      onUpdateInfo: _cache[25] || (_cache[25] = ($event: any) => (_ctx.updateCardInfo(_ctx.CardComponentsType.Financial)))
    }, null, 8, ["type", "id"]),
    _createVNode(_component_TaxEditModal, {
      ref: "taxEditModal",
      type: _ctx.getKycCommonTypeParams.entityType,
      id: Number(_ctx.taxEditId),
      onUpdateInfo: _cache[26] || (_cache[26] = ($event: any) => (_ctx.updateCardInfo(_ctx.CardComponentsType.Tax)))
    }, null, 8, ["type", "id"])
  ]))
}