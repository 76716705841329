
import { defineComponent, onMounted, ref, useAttrs, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { setModuleBCN } from "@/core/directive/function/common";
import { MenuComponent } from "@/assets/ts/components";
import { NumberOrString } from "@/core/directive/type/common";
import Swal from "sweetalert2";
import mixin from "@/mixins";
import {
  KycType,
  KycStatus,
  CardComponentsType,
} from "@/core/directive/type/kyc";
import { getKycCommonTypeParams } from "@/core/directive/function/kyc";
import { ApiKyc } from "@/core/api";
import { IPagination } from "@/core/directive/interface/common";

import {
  ContactInfo,
  ContactInfoData,
  LegalInfo,
  legalInfoData,
  BillingAddressInfo,
  billingAddressInfoData,
  WarehouseInfo,
  warehouseInfoData,
  InventoryInfo,
  inventoryInfoData,
  FinancialInfo,
  financialInfoData,
  TaxInfo,
  taxInfoData,
  SettlementEntityOverview,
  settlementEntityOverviewData,
} from "@/core/directive/interface/kyc";

import ContactCard from "@/components/page/kyc/common/ContactCard.vue";
import RegistrationCard from "@/components/page/kyc/common/RegistrationCard.vue";
import BillingCard from "@/components/page/kyc/common/BillingCard.vue";
import WarehouseCard from "@/components/page/kyc/common/WarehouseCard.vue";
import InventoryCard from "@/components/page/kyc/common/InventoryCard.vue";
import FinancialCard from "@/components/page/kyc/common/FinancialCard.vue";
import TaxCard from "@/components/page/kyc/common/TaxCard.vue";
import ContactEditModal from "@/components/page/kyc/common/ContactEditModal.vue";
import RegistrationEditModal from "@/components/page/kyc/common/RegistrationEditModal.vue";
import BillingEditModal from "@/components/page/kyc/common/BillingEditModal.vue";
import WarehouseEditModal from "@/components/page/kyc/common/WarehouseEditModal.vue";
import InventoryEditModal from "@/components/page/kyc/common/InventoryEditModal.vue";
import FinancialEditModal from "@/components/page/kyc/common/FinancialEditModal.vue";
import TaxEditModal from "@/components/page/kyc/common/TaxEditModal.vue";
import EmptyData from "@/components/layout/EmptyData.vue";
import _ from "lodash";

export default defineComponent({
  components: {
    ContactCard,
    RegistrationCard,
    BillingCard,
    WarehouseCard,
    InventoryCard,
    FinancialCard,
    TaxCard,
    ContactEditModal,
    RegistrationEditModal,
    BillingEditModal,
    WarehouseEditModal,
    InventoryEditModal,
    FinancialEditModal,
    TaxEditModal,
    EmptyData,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const attrs = useAttrs();
    const loading = ref(false);
    const { showServerErrorMsg, showFormSubmitSuccessMsg } = mixin();

    const baseData = ref(
      Object.assign({}, _.cloneDeep(settlementEntityOverviewData))
    );

    const modalLoading = ref(false);
    const applicationScenarios =
      getKycCommonTypeParams.value.applicationScenarios;
    const settlementEntityId = route.params.id;

    const contactView = ref<ContactInfo>(ContactInfoData);
    const registrationView = ref<LegalInfo>(legalInfoData);
    const billingList = ref<BillingAddressInfo[]>([billingAddressInfoData]);
    const warehouseList = ref<WarehouseInfo[]>([warehouseInfoData]);
    const inventoryList = ref<InventoryInfo[]>([inventoryInfoData]);
    const financialList = ref<FinancialInfo[]>([financialInfoData]);
    const taxList = ref<TaxInfo[]>([taxInfoData]);

    const contactEditModal = ref();
    const contactEditId = ref<NumberOrString>(0);
    const registrationEditModal = ref();
    const registrationEditId = ref<NumberOrString>(0);
    const billingEditModal = ref();
    const billingEditId = ref<NumberOrString>(0);
    const warehouseEditModal = ref();
    const warehouseEditId = ref<NumberOrString>(0);
    const inventoryEditModal = ref();
    const inventoryEditId = ref<NumberOrString>(0);
    const financialEditModal = ref();
    const financialEditId = ref<NumberOrString>(0);
    const taxEditModal = ref();
    const taxEditId = ref<NumberOrString>(0);

    const submitKycLevelTarget = ref<CardComponentsType>(
      CardComponentsType.Contact
    );

    const cardComponentsLoading = ref({
      [CardComponentsType.Contact]: false,
      [CardComponentsType.Registration]: false,
      [CardComponentsType.Billing]: false,
      [CardComponentsType.Warehouse]: false,
      [CardComponentsType.Inventory]: false,
      [CardComponentsType.Financial]: false,
      [CardComponentsType.Tax]: false,
    });

    watch(
      () => attrs.baseData,
      (newValue) => {
        const base = newValue as SettlementEntityOverview;
        baseData.value = base;
      },
      { immediate: true }
    );

    /**
     * 页面控制
     */

    // 分页
    const billingPagination = ref<IPagination>({
      page: 1,
      total: 1,
      rowsPerPage: 5,
    });
    const warehousePagination = ref<IPagination>({
      page: 1,
      total: 1,
      rowsPerPage: 5,
    });
    const inventoryPagination = ref<IPagination>({
      page: 1,
      total: 1,
      rowsPerPage: 5,
    });
    const financialPagination = ref<IPagination>({
      page: 1,
      total: 1,
      rowsPerPage: 5,
    });
    const taxPagination = ref<IPagination>({
      page: 1,
      total: 1,
      rowsPerPage: 5,
    });

    // 按钮显示
    const buttonShowControl = (item?: any, length?: any) => {
      const buttonShow = {
        add: false,
        edit: false,
        delete: false,
        submit: false,
      };
      if (item) {
        if (
          item.identification.status === KycStatus.Draft ||
          item.identification.status === KycStatus.Disapproved
        ) {
          buttonShow.edit = true;
          buttonShow.delete = true;
          buttonShow.submit = true;
        }
      }
      if (!length || length < 10) {
        buttonShow.add = true;
      }
      return buttonShow;
    };

    const isNullData = (params: any) => {
      let isNull =
        params === null ||
        params === undefined ||
        JSON.stringify(params) === "[]" ||
        JSON.stringify(params) === "{}" ||
        JSON.stringify(params) === `""`;
      return isNull;
    };

    /**
     * 请求数据
     */

    // 联系信息
    const getContactStateAddress = async () => {
      cardComponentsLoading.value[CardComponentsType.Contact] = true;
      const { data } =
        await getKycCommonTypeParams.value.getContactStateAddressApi({
          application_scenarios: applicationScenarios,
          settlement_entity_id: settlementEntityId,
        });
      cardComponentsLoading.value[CardComponentsType.Contact] = false;
      if (data.code === 0) {
        contactView.value = data.data;
      } else {
        showServerErrorMsg(data);
      }
      reinitMenuComponent();
    };
    // 注册信息
    const getRegistrationStateAddress = async () => {
      cardComponentsLoading.value[CardComponentsType.Registration] = true;
      const { data } =
        await getKycCommonTypeParams.value.getRegistrationStateAddressApi({
          application_scenarios: applicationScenarios,
          settlement_entity_id: settlementEntityId,
        });
      cardComponentsLoading.value[CardComponentsType.Registration] = false;
      if (data.code === 0) {
        registrationView.value = data.data;
      } else {
        showServerErrorMsg(data);
      }
      reinitMenuComponent();
    };
    // 账单信息
    const getBillingList = async () => {
      cardComponentsLoading.value[CardComponentsType.Billing] = true;
      const { data } = await ApiKyc.getBillingList({
        application_scenarios: applicationScenarios,
        settlement_entity_id: settlementEntityId,
        page: billingPagination.value.page,
        page_size: billingPagination.value.rowsPerPage,
      });
      cardComponentsLoading.value[CardComponentsType.Billing] = false;
      if (data.code === 0) {
        billingList.value.splice(
          0,
          billingList.value.length,
          ...data.data.items
        );
        billingPagination.value.total = data.data.total;
      } else {
        showServerErrorMsg(data);
      }
      reinitMenuComponent();
    };
    // 仓库信息
    const getWarehouseList = async () => {
      cardComponentsLoading.value[CardComponentsType.Warehouse] = true;
      const { data } = await ApiKyc.getWarehouseList({
        application_scenarios: applicationScenarios,
        settlement_entity_id: settlementEntityId,
        page: warehousePagination.value.page,
        page_size: warehousePagination.value.rowsPerPage,
      });
      cardComponentsLoading.value[CardComponentsType.Warehouse] = false;
      if (data.code === 0) {
        warehouseList.value.splice(
          0,
          warehouseList.value.length,
          ...data.data.items
        );
        warehousePagination.value.total = data.data.total;
      } else {
        showServerErrorMsg(data);
      }
      reinitMenuComponent();
    };
    // 库存信息
    const getInventoryList = async () => {
      cardComponentsLoading.value[CardComponentsType.Inventory] = true;
      const { data } = await ApiKyc.getInventoryList({
        application_scenarios: applicationScenarios,
        settlement_entity_id: settlementEntityId,
        page: inventoryPagination.value.page,
        page_size: inventoryPagination.value.rowsPerPage,
      });
      cardComponentsLoading.value[CardComponentsType.Inventory] = false;
      if (data.code === 0) {
        inventoryList.value.splice(
          0,
          inventoryList.value.length,
          ...data.data.items
        );
        inventoryPagination.value.total = data.data.total;
      } else {
        showServerErrorMsg(data);
      }
      reinitMenuComponent();
    };
    // 财务信息
    const getFinancialList = async () => {
      cardComponentsLoading.value[CardComponentsType.Financial] = true;
      const { data } = await ApiKyc.getFinancialList({
        application_scenarios: applicationScenarios,
        settlement_entity_id: settlementEntityId,
        page: financialPagination.value.page,
        page_size: financialPagination.value.rowsPerPage,
      });
      cardComponentsLoading.value[CardComponentsType.Financial] = false;
      if (data.code === 0) {
        financialList.value.splice(
          0,
          financialList.value.length,
          ...data.data.items
        );
        financialPagination.value.total = data.data.total;
      } else {
        showServerErrorMsg(data);
      }
      reinitMenuComponent();
    };
    // 税务信息
    const getTaxList = async () => {
      cardComponentsLoading.value[CardComponentsType.Tax] = true;
      const { data } = await ApiKyc.getTaxList({
        application_scenarios: applicationScenarios,
        settlement_entity_id: settlementEntityId,
        page: taxPagination.value.page,
        page_size: taxPagination.value.rowsPerPage,
      });
      cardComponentsLoading.value[CardComponentsType.Tax] = false;
      if (data.code === 0) {
        taxList.value.splice(0, taxList.value.length, ...data.data.items);
        taxPagination.value.total == data.data.total;
      } else {
        showServerErrorMsg(data);
      }
      reinitMenuComponent();
    };

    const getAllList = async () => {
      loading.value = true;
      Promise.all([
        getContactStateAddress(),
        getRegistrationStateAddress(),
        getBillingList(),
        getWarehouseList(),
        getInventoryList(),
        getFinancialList(),
        getTaxList(),
      ])
        .then(() => {
          loading.value = false;
          MenuComponent.reinitialization();
        })
        .catch((error) => {
          console.log(error);
        });
    };

    /**
     * 编辑数据
     */

    // 联系信息
    const editContact = (id: NumberOrString) => {
      contactEditId.value = id;
      contactEditModal.value?.modalShow();
    };

    // 注册信息
    const addRegistration = () => {
      registrationEditId.value = 0;
      registrationEditModal.value?.modalShow();
    };
    const editRegistration = (id: NumberOrString) => {
      registrationEditId.value = id;
      registrationEditModal.value?.modalShow();
    };

    // 账单信息
    const addBilling = () => {
      billingEditId.value = 0;
      billingEditModal.value?.modalShow();
    };
    const editBilling = (id: NumberOrString) => {
      billingEditId.value = id;
      billingEditModal.value?.modalShow();
    };

    // 仓库信息
    const addWarehouse = () => {
      warehouseEditId.value = 0;
      warehouseEditModal.value?.modalShow();
    };
    const editWarehouse = (id: NumberOrString) => {
      warehouseEditId.value = id;
      warehouseEditModal.value?.modalShow();
    };

    // 库存信息
    const addInventory = () => {
      inventoryEditId.value = 0;
      inventoryEditModal.value?.modalShow();
    };
    const editInventory = (id: NumberOrString) => {
      inventoryEditId.value = id;
      inventoryEditModal.value?.modalShow();
    };

    // 财务信息
    const addFinancial = () => {
      financialEditId.value = 0;
      financialEditModal.value?.modalShow();
    };
    const editFinancial = (id: NumberOrString) => {
      financialEditId.value = id;
      financialEditModal.value?.modalShow();
    };

    // 税务信息
    const addTax = () => {
      taxEditId.value = 0;
      taxEditModal.value?.modalShow();
    };
    const editTax = (id: NumberOrString) => {
      taxEditId.value = id;
      taxEditModal.value?.modalShow();
    };

    /**
     * 刪除数据
     */
    const onDeleteButtonClick = (
      target: CardComponentsType,
      id: NumberOrString
    ) => {
      Swal.fire({
        text: t("kyc.tips.kycVerificationDeleteTips"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.noCancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-body",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (target === CardComponentsType.Billing) {
            deleteBillingFunc(id);
          } else if (target === CardComponentsType.Warehouse) {
            deleteWarehouseFunc(id);
          } else if (target === CardComponentsType.Inventory) {
            deleteInventoryFunc(id);
          } else if (target === CardComponentsType.Financial) {
            deleteFinancialFunc(id);
          } else if (target === CardComponentsType.Tax) {
            deleteTaxFunc(id);
          }
        }
      });
    };
    const deleteBillingFunc = async (id: NumberOrString) => {
      const { data } = await ApiKyc.deleteBilling({
        id: id,
      });
      if (data.code === 0) {
        showFormSubmitSuccessMsg(() => {
          getBillingList();
        });
      } else {
        showServerErrorMsg(data);
      }
    };
    const deleteWarehouseFunc = async (id: NumberOrString) => {
      const { data } = await ApiKyc.deleteWarehouse({
        id: id,
      });
      if (data.code === 0) {
        showFormSubmitSuccessMsg(() => {
          getWarehouseList();
        });
      } else {
        showServerErrorMsg(data);
      }
    };
    const deleteInventoryFunc = async (id: NumberOrString) => {
      const { data } = await ApiKyc.deleteInventory({
        id: id,
      });
      if (data.code === 0) {
        showFormSubmitSuccessMsg(() => {
          getInventoryList();
        });
      } else {
        showServerErrorMsg(data);
      }
    };
    const deleteFinancialFunc = async (id: NumberOrString) => {
      const { data } = await ApiKyc.deleteFinancial({
        id: id,
      });
      if (data.code === 0) {
        showFormSubmitSuccessMsg(() => {
          getFinancialList();
        });
      } else {
        showServerErrorMsg(data);
      }
    };
    const deleteTaxFunc = async (id: NumberOrString) => {
      const { data } = await ApiKyc.deleteTax({
        id: id,
      });
      if (data.code === 0) {
        showFormSubmitSuccessMsg(() => {
          getTaxList();
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    /**
     * 提交审批
     */

    const isAddressValid = (
      country_code,
      country,
      province,
      city,
      district,
      address1,
      address2,
      postcode
    ) => {
      if (country_code == "CN") {
        return (
          country &&
          province &&
          city &&
          district &&
          address1 &&
          address2 &&
          postcode
        );
      } else if (country_code == "TW") {
        return country && province && city && address1 && address2 && postcode;
      } else {
        return country && city && address1 && address2 && postcode;
      }
    };

    const isContactDataValid = () => {
      if (getKycCommonTypeParams.value.entityType == KycType.Juridical) {
        return (
          contactView.value.identification.company_name &&
          isAddressValid(
            contactView.value.identification.country_code,
            contactView.value.identification.country,
            contactView.value.identification.province,
            contactView.value.identification.city,
            contactView.value.identification.district,
            contactView.value.identification.address1,
            contactView.value.identification.address2,
            contactView.value.identification.postcode
          )
        );
      } else if (getKycCommonTypeParams.value.entityType == KycType.Natural) {
        return (
          contactView.value.identification.firstname &&
          contactView.value.identification.lastname &&
          contactView.value.identification.area_code &&
          contactView.value.identification.telephone &&
          isAddressValid(
            contactView.value.identification.country_code,
            contactView.value.identification.country,
            contactView.value.identification.province,
            contactView.value.identification.city,
            contactView.value.identification.district,
            contactView.value.identification.address1,
            contactView.value.identification.address2,
            contactView.value.identification.postcode
          )
        );
      }
    };

    const isRegistrationDataValid = () => {
      if (getKycCommonTypeParams.value.entityType == KycType.Juridical) {
        return (
          registrationView.value.identification.registered_company_name &&
          registrationView.value.identification.company_legal_form &&
          registrationView.value.identification.company_registration_number &&
          registrationView.value.identification.registration_authority &&
          registrationView.value.identification.legal_representative_name &&
          isAddressValid(
            registrationView.value.identification.country_code,
            registrationView.value.identification.country,
            registrationView.value.identification.province,
            registrationView.value.identification.city,
            registrationView.value.identification.district,
            registrationView.value.identification.address1,
            registrationView.value.identification.address2,
            registrationView.value.identification.postcode
          )
        );
      } else if (getKycCommonTypeParams.value.entityType == KycType.Natural) {
        return (
          registrationView.value.identification.firstname &&
          registrationView.value.identification.lastname &&
          registrationView.value.identification.passport &&
          registrationView.value.identification.birthday &&
          registrationView.value.identification.data_of_issue &&
          registrationView.value.identification.validity_period &&
          registrationView.value.identification.id_card &&
          registrationView.value.identification.id_card_validity_period &&
          isAddressValid(
            registrationView.value.identification.country_code,
            registrationView.value.identification.country,
            registrationView.value.identification.province,
            registrationView.value.identification.city,
            registrationView.value.identification.district,
            registrationView.value.identification.address1,
            registrationView.value.identification.address2,
            registrationView.value.identification.postcode
          )
        );
      }
    };

    const onSubmitForApprovalClick = (
      target: CardComponentsType,
      id: NumberOrString
    ) => {
      submitKycLevelTarget.value = target;
      if (target == CardComponentsType.Contact) {
        let valid = isContactDataValid();
        if (!valid) {
          Swal.fire({
            title: t("kyc.information.common.tips"),
            icon: "warning",
            buttonsStyling: false,
            showCancelButton: false,
            confirmButtonText: t("common.okGotIt"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return;
        }
      } else if (target == CardComponentsType.Registration) {
        let valid = isRegistrationDataValid();
        if (!valid) {
          Swal.fire({
            title: t("kyc.information.common.tips"),
            icon: "warning",
            buttonsStyling: false,
            showCancelButton: false,
            confirmButtonText: t("common.okGotIt"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return;
        }
      }
      Swal.fire({
        text: t("kyc.tips.kycVerificationSubmitForApprovalTips"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.noCancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-body",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          submitForApprovalFunc(target, id);
        }
      });
    };

    const submitForApprovalFunc = async (
      refreshTarget: CardComponentsType,
      id: NumberOrString,
      kyc_level?: NumberOrString
    ) => {
      let params;
      if (kyc_level) {
        params = {
          id: id,
          kyc_level: kyc_level,
        };
      } else {
        params = {
          id: id,
        };
      }
      const { data } = await ApiKyc.submitToApproval({
        ...params,
      });
      if (data.code === 0) {
        showFormSubmitSuccessMsg(() => {
          updateCardInfo(refreshTarget);
        });
      } else {
        showServerErrorMsg(data);
      }
    };

    /**
     * 更新指定模块信息
     */

    const updateCardInfo = (type: CardComponentsType) => {
      if (type == CardComponentsType.Contact) {
        getContactStateAddress();
      } else if (type == CardComponentsType.Registration) {
        getRegistrationStateAddress();
      } else if (type == CardComponentsType.Billing) {
        getBillingList();
      } else if (type == CardComponentsType.Warehouse) {
        getWarehouseList();
      } else if (type == CardComponentsType.Inventory) {
        getInventoryList();
      } else if (type == CardComponentsType.Financial) {
        getFinancialList();
      } else if (type == CardComponentsType.Tax) {
        getTaxList();
      }
    };

    const reinitMenuComponent = () => {
      MenuComponent.reinitialization();
    };

    onMounted(() => {
      getAllList();
      MenuComponent.reinitialization();
      setModuleBCN(t, route, router);
    });

    return {
      loading,
      modalLoading,
      baseData,
      cardComponentsLoading,
      getKycCommonTypeParams,
      KycType,
      CardComponentsType,
      updateCardInfo,
      //请求数据
      contactView,
      registrationView,
      billingList,
      warehouseList,
      inventoryList,
      financialList,
      taxList,
      //分页
      billingPagination,
      warehousePagination,
      inventoryPagination,
      financialPagination,
      taxPagination,
      //按钮显示
      buttonShowControl,
      isNullData,
      //编辑数据
      contactEditModal,
      contactEditId,
      editContact,
      registrationEditModal,
      registrationEditId,
      addRegistration,
      editRegistration,
      billingEditModal,
      billingEditId,
      addBilling,
      editBilling,
      warehouseEditModal,
      warehouseEditId,
      addWarehouse,
      editWarehouse,
      inventoryEditModal,
      inventoryEditId,
      addInventory,
      editInventory,
      financialEditModal,
      financialEditId,
      addFinancial,
      editFinancial,
      taxEditModal,
      taxEditId,
      addTax,
      editTax,
      // 刪除数据
      onDeleteButtonClick,
      // 提交审批
      onSubmitForApprovalClick,
      submitKycLevelTarget,
    };
  },
});
